// React
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

// Material UI
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogContent
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'

// Redux
import { useDispatch, useSelector } from 'react-redux'

// Libs
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'

// Components
import {
  integrationDisconnectRequest,
  integrationRequest
} from '../../../../../../store/modules/integration/actions'
import { userListRequest } from '../../../../../../store/modules/user/actions'
import Tooltip from '../../../../../../components/Tooltip'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },

  margin: {
    marginBottom: theme.spacing(1)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 15
  },
  cardAction: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end'
  },
  selectSellerProft: {
    width: '100%'
  }
}))

import {
  Container,
  SellersList,
  Seller,
  StatusConteiner,
  SellersFound,
  SellerInfo,
  RadioContent,
  ActualSeller,
  Counters,
  ActiveCounter,
  OtherCounter,
  InactiveCounter,
  EditSellerIcon,
  SelectSeller,
  HeaderDialog,
  SelectForm
} from './styles'
import { normalizeString } from 'utils/normalizeString'
import api from 'repositories/api'

const Allsoft = ({ storeIntegrationData, store, loading }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const provider = 'allSoft'

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      username: '',
      password: '',
      storeId: ''
    }
  })

  const [logout, setLogout] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [page, setPage] = useState(10)

  // Dictionary
  const [userDict, setUserDict] = useState({})
  const options = usersList.map(user => {
    const { type } = user.stores.find(
      userStore => userStore.storeId === store._id
    )

    return { label: user.name.complete, cpf: user.cpf, type }
  })

  // Dialog
  const [optionValue, setOptionValue] = useState('')
  const [sellerCpf, setSellerCpf] = useState('')
  const [editSellerModal, setEditSellerModal] = useState(false)

  const [actualSeller, setActualSeller] = useState({
    name: '',
    type: ''
  })

  // Counter for sellers
  const [stateCount, setStateCount] = useState({
    Inactive: 0,
    Other: 0,
    Seller: 0
  })

  // LocalStorage
  const { storeId } = useParams()
  const token = localStorage.getItem('@NeoPro:token')

  // Custom styles for Select Options in Modal
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#f9f9fb' : '#000',
      // Override borderColor when clicked

      // Override active/selected option styles
      backgroundColor: state.isSelected ? 'green' : 'white',

      // Override backgroud color when hover
      backgroundColor: state.isFocused ? '#1E2FA9' : 'white',

      ':active': {
        backgroundColor: state.isSelected && '#1E2FA9'
      },
      fontFamily: 'Roboto',
      fontSize: 14
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
    control: provided => ({
      ...provided,
      fontFamily: 'Roboto',
      fontSize: 14
    })
  }

  const getUserList = async (page, limit) => {
    try {
      const response = await api.axios.get(`/user/store/${storeId}`, {
        params: {
          page,
          limit: limit ? limit : ''
        },
        headers: { Authorization: `Bearer ${token}` }
      })

      console.log('res', response.data)
      setUsersList(response.data.items)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!!storeId && !!token) {
      getUserList(1, 10)
    }
  }, [])

  // Submit form
  const onSubmit = data => {
    storeIntegrationData?.provider === provider && userDict
      ? confirm({
          title: 'Atenção',
          description:
            'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na NeoPro e na Allsoft?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar'
        })
          .then(() => {
            dispatch(
              integrationRequest(
                {
                  ...store.integration,
                  userDict,
                  provider
                },
                provider,
                storeId,
                token
              )
            )
          })
          .catch(() => {})
      : dispatch(
          integrationRequest({ ...data, provider }, provider, storeId, token)
        )
  }

  // Disconnect integration
  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description:
        'Você tem certeza que deseja remover a integração com Allsoft?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        setLogout(true)
        dispatch(integrationDisconnectRequest(provider, storeId, token))
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (
      storeIntegrationData?.username &&
      storeIntegrationData?.provider === provider
    )
      reset({
        username: storeIntegrationData.username,
        password: storeIntegrationData.password,
        storeId: storeIntegrationData.storeId
      })
  }, [storeIntegrationData])

  const renderError = error =>
    errors[error] && (
      <FormHelperText id='component-error-text'>
        Este campo é obrigatório
      </FormHelperText>
    )

  // Identify the dictonary (userDict)
  useEffect(() => {
    if (storeIntegrationData.userDict) {
      setUserDict(storeIntegrationData.userDict)
      setStateCount(
        Object.values(storeIntegrationData.userDict).reduce(
          (acc, value) => {
            acc[String(value)] >= 0 ? acc[String(value)]++ : acc.Seller++
            return acc
          },
          {
            Inactive: 0,
            Other: 0,
            Seller: 0
          }
        )
      )
    }
  }, [])

  const handleSeller = (name, type) => {
    //Verifica se o type (Inactive, Seller ou Other) antigo é diferente do atual
    if (
      userDict[name] !== type ||
      userDict[name] !== sellerCpf ||
      userDict[name] !== type.cpf
    ) {
      //Atualiza para string para poder fazer a leitura de "Inactive"
      const oldType = String(userDict[name])
      const newType = String(sellerCpf || type.cpf || type)

      //Verifica se é >= 0 já que 0 é considerado falso, então cairia sempre na próxima execução
      //Caso o type antigo seja >= 0, iremos decrescer o contador, caso contrário acrescerá
      //Caso não seja Inactive ou Other, será o CPF do vendedor, e a função retorna o index desse vendedor, então já passamos direto o stateCount.seller
      stateCount[oldType] >= 0 ? stateCount[oldType]-- : stateCount.Seller--
      //Verifica o novo type que está sendo setado e irá acrescer do mesmo
      stateCount[newType] >= 0 ? stateCount[newType]++ : stateCount.Seller++

      setStateCount(stateCount)
    }
    //Altera a estrutura de dados de userDict, modificando o CPF do vendedor para o presente na PROFT
    setUserDict({ ...userDict, [name]: sellerCpf || type.cpf || type })
    setEditSellerModal(false)
  }

  // LoadOptions for SelectSeller (Modal)
  const loadOptions = async (search, prevOptions) => {
    let filteredOptions

    if (!search) {
      filteredOptions = options.filter(({ type }) => type === 'seller')
    } else {
      filteredOptions = options.filter(
        ({ label, type }) =>
          label.toLowerCase().includes(search.toLowerCase()) &&
          type === 'seller'
      )
    }

    const sellersOnStoreLength = store?.users?.filter(user => {
      return Boolean(
        user.stores.find(store => store.storeId === storeId)?.type === 'seller'
      )
    }).length

    const slicedOptions = filteredOptions.slice(prevOptions.length)

    if (page < 80) {
      setPage(prev => prev + 10)
      await getUserList(1, page)
    }

    const hasMore = !!search
      ? page < 100
      : sellersOnStoreLength > filteredOptions.length

    return {
      options: slicedOptions,
      hasMore
    }
  }

  return (
    <Card>
      <form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title='Allsoft' />
        <Divider />
        <CardContent>
          <Typography>
            Antes de iniciar a integração, certifique-se de que todos os
            vendedores estão cadastrados, assim como seus CPFs estão definidos e
            idênticos à Allsoft. Caso haja qualquer diferença nos valores, o
            vendedor não será identificado e a venda entrará em "Outras vendas",
            prejudicando a validade do relatório.
            <br />
            Se acontecer de um vendedor não ter sido identificado e sua venda
            entrar em "Outras vendas", corrija os dados do vendedor e aguarde a
            próxima sincronização.
            <br />A sincronização acontece todo dia à 01:00 AM
          </Typography>
          {storeIntegrationData?.provider !== provider ? (
            <>
              <Divider style={{ marginTop: '1rem' }} />
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label='ID'
                    placeholder='(Ex: abcd1234)'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    id='storeId'
                    name='storeId'
                    className={classes.textField}
                    onFocus={event => event.target.select()}
                    inputRef={register({ required: true })}
                    error={!!errors.storeId}
                  />
                  {renderError('storeId')}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label='Login'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    id='username'
                    name='username'
                    className={classes.textField}
                    onFocus={event => event.target.select()}
                    inputRef={register({ required: true })}
                    error={!!errors.username}
                  />
                  {renderError('username')}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    label='Senha'
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    id='password'
                    name='password'
                    type='password'
                    className={classes.textField}
                    onFocus={event => event.target.select()}
                    inputRef={register({ required: true })}
                    error={!!errors.password}
                    inputProps={{
                      'data-clarity-mask': 'True'
                    }}
                  />
                  {renderError('password')}
                </Grid>
              </Grid>
            </>
          ) : (
            <Container>
              <SellersFound>
                <Typography variant='h6'>Vendedores encontrados </Typography>
                {storeIntegrationData.userDict && (
                  <Counters>
                    <InactiveCounter>{stateCount.Inactive}</InactiveCounter>
                    <OtherCounter>{stateCount.Other}</OtherCounter>
                    <ActiveCounter>{stateCount.Seller}</ActiveCounter>
                  </Counters>
                )}
              </SellersFound>
              <SellersList>
                {storeIntegrationData.userDict ? (
                  Object.entries(userDict)
                    .sort((a, b) =>
                      a[0].toLowerCase().localeCompare(b[0].toLowerCase())
                    )
                    .map(([seller, type], index) => (
                      <Seller
                        key={index}
                        onClick={() => {
                          setEditSellerModal(true)
                          setActualSeller({ name: seller, type: type })
                          setOptionValue(type)
                        }}
                      >
                        <SellerInfo>
                          <Typography
                            variant='subtitle2'
                            style={{ fontWeight: 700 }}
                          >
                            {seller}
                          </Typography>
                          <StatusConteiner>
                            <Typography
                              variant='body2'
                              style={{ fontWeight: 700 }}
                            >
                              NeoPro:
                            </Typography>
                            <Typography
                              variant='body2'
                              style={{
                                color:
                                  type === 'Inactive'
                                    ? '#e74c3c'
                                    : type === 'Other'
                                    ? '#f39c12'
                                    : '#2ecc71',
                                fontWeight: 700
                              }}
                            >
                              {{
                                Inactive: 'Inativo',
                                Other: 'Vendas da loja'
                              }[type] || [
                                options.find(option => option.cpf === type)
                                  ?.label || type
                              ]}
                            </Typography>
                          </StatusConteiner>
                        </SellerInfo>
                        <EditSellerIcon />
                      </Seller>
                    ))
                ) : (
                  <div
                    style={{
                      padding: '20px',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant='subtitle2'>
                      Não encontramos vendedores cadastrados.
                    </Typography>
                  </div>
                )}
              </SellersList>
            </Container>
          )}
        </CardContent>
        <CardActions>
          <Button
            color='primary'
            variant='contained'
            disabled={loading}
            className={classes.button}
            type='submit'
          >
            {loading ? (
              <>
                <CircularProgress
                  size={24}
                  style={{ color: '#fff', marginRight: '8px' }}
                />
                {storeIntegrationData?.provider === provider
                  ? 'Atualizando...'
                  : 'Conectando ao Allsoft...'}
              </>
            ) : storeIntegrationData?.provider === provider ? (
              'Atualizar'
            ) : (
              'Salvar'
            )}
          </Button>
          {storeIntegrationData?.provider === provider && (
            <Button
              style={{ color: '#e74c3c' }}
              className={classes.button}
              disabled={loading && logout}
              onClick={() => handleDisconnectIntegration()}
            >
              {loading && logout ? (
                <>
                  <CircularProgress
                    size={24}
                    style={{ color: '#e74c3c', marginRight: '8px' }}
                  />{' '}
                  {' Desconectando...'}
                </>
              ) : (
                'Desconectar'
              )}
            </Button>
          )}
        </CardActions>
      </form>

      {/* Edit Modal User */}
      <Dialog
        open={editSellerModal}
        fullWidth
        onClose={() => {
          setEditSellerModal(false)
          setOptionValue('')
          setSellerCpf('')
          setActualSeller({ name: '', type: '' })
        }}
        maxWidth='xs'
      >
        <DialogContent className={classes.dialogContent}>
          <HeaderDialog>
            <Typography variant='h4'>Vincular vendedor</Typography>
            <ActualSeller>{actualSeller.name}</ActualSeller>
            <Typography variant='subtitle2'>
              Selecione como deseja vincular o vendedor:
            </Typography>
          </HeaderDialog>
          <RadioGroup
            defaultValue={
              actualSeller.type !== 'Inactive' && actualSeller.type !== 'Other'
                ? 'Seller'
                : actualSeller.type
            }
            name='radio-buttons-group'
          >
            <SelectSeller>
              <FormControlLabel
                data-for='tooltip-sales-others'
                data-tip='Habilite “Agrupar outras vendas” nas configurações de integração do ERP'
                value='Seller'
                control={<Radio size='small' />}
                label={<RadioContent />}
                onChange={e => setOptionValue(e.target.value)}
                disabled={!storeIntegrationData.useOtherSales}
                style={{ marginRight: 0 }}
              />
              <SelectForm>
                <AsyncPaginate
                  defaultValue={options.find(
                    option => option.cpf === actualSeller.type
                  )}
                  loadOptions={loadOptions}
                  placeholder='Selecione um vendedor'
                  noOptionsMessage={() => 'Nenhum vendedor encontrado'}
                  loadingMessage={() => 'Carregando...'}
                  className={classes.selectSellerProft}
                  maxMenuHeight='140px'
                  styles={customStyles}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: 'hotpink',
                      primary: '#1E2FA9'
                    }
                  })}
                  onChange={value => {
                    setSellerCpf(value.cpf)
                    setOptionValue(value.type)
                  }}
                  isDisabled={
                    normalizeString(optionValue) === 'inactive' ||
                    normalizeString(optionValue) === 'other' ||
                    !actualSeller.type
                  }
                />
                <Typography variant='subtitle2' style={{ paddingLeft: 5 }}>
                  Vincule o usuário do vendedor na NeoPro com as vendas do ERP.
                </Typography>
              </SelectForm>
            </SelectSeller>
            {!storeIntegrationData.useOtherSales && (
              <Tooltip id='tooltip-sales-others' place='left' />
            )}
            <Divider />
            <FormControlLabel
              data-for='tooltip-sales-others'
              data-tip='Habilite “Agrupar outras vendas” nas configurações de integração do ERP'
              value='Other'
              control={<Radio size='small' />}
              label={
                <RadioContent>
                  <Typography variant='h6'>Outras vendas</Typography>
                  <Typography variant='subtitle2'>
                    Agrupe as vendas do vendedor do ERP em Outras vendas na
                    NeoPro.
                  </Typography>
                </RadioContent>
              }
              onChange={e => setOptionValue(e.target.value)}
              disabled={!storeIntegrationData.useOtherSales}
            />
            <Divider />
            <FormControlLabel
              value='Inactive'
              control={<Radio size='small' />}
              label={
                <RadioContent>
                  <Typography variant='h6'>Inativo</Typography>
                  <Typography variant='subtitle2'>
                    Desconsidere as vendas do ERP desse vendedor.
                  </Typography>
                </RadioContent>
              }
              onChange={e => setOptionValue(e.target.value)}
            />
          </RadioGroup>
        </DialogContent>
        <CardActions className={classes.cardAction}>
          <Typography
            variant='subtitle2'
            style={{ cursor: 'pointer' }}
            onClick={() => setEditSellerModal(false)}
          >
            Cancelar
          </Typography>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            type='submit'
            onClick={() => {
              actualSeller && optionValue
                ? handleSeller(
                    actualSeller.name,
                    Object.values(
                      store.users.map(seller => seller.name.complete)
                    )[optionValue] || optionValue
                  )
                : toast.error('Confirme a escolha do vendedor')
              setOptionValue('')
              setSellerCpf('')
            }}
          >
            Salvar
          </Button>
        </CardActions>
      </Dialog>
    </Card>
  )
}

Allsoft.propTypes = {
  store: PropTypes.object,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool
}

export default Allsoft
