import { useEffect, useRef, useState } from 'react'
import { Element, Link, animateScroll as scroll } from 'react-scroll'

import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import { getDay } from 'date-fns/esm'
import getDate from 'date-fns/getDate'
import { endOfMonth, getWeeksInMonth, startOfMonth } from 'date-fns'
import Lottie from 'react-lottie'
import { useParams } from 'react-router'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import { storeRequest } from 'store/modules/store/actions'

// Material
import CreateIcon from '@material-ui/icons/Create'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import { Close, Info } from '@material-ui/icons'
import { CircularProgress, Modal } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import SpeedIcon from '@material-ui/icons/Speed'
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined'
import LockIcon from '@material-ui/icons/LockOutlined'

// components
import TransferListCard from 'components/UsersGoal/components/TransferListCard'
import GoalsTable from './Components/GoalsTable'
import Calendar from './Components/Calendar'
import Tooltip from 'components/Tooltip'
import SectionPicker from './Components/SectionPicker'
import { SuggestionAnimation } from './Components/Calendar/Components/CardDay/style'
import PromptModal from '../../PromptModal'

import { formatPrice } from 'utils/format'
import api from 'repositories/api'

// Styles
import {
  Container,
  ContentBox,
  CalendarContainer,
  ModalContainer,
  AccWeightCounter
} from './style'
import LockArrow from 'assets/Icons/LockArrow'
import WandIcon from 'assets/Icons/WandIcon'
import wandAnimation from 'assets/wandAnim.json'
import { toast } from 'react-toastify'
import ToggleItem from './Components/GoalsTable/components/ToggleItem'
import {
  clearDisabledDays,
  setInitialCalendarValues
} from 'store/modules/calendarGoal/actions'
import palette from 'theme/palette'
import { deepClone } from 'utils/deepClone'
import { IStoreProps } from 'store/modules/store/types'
import { ICalendarGoalProps } from 'store/modules/calendarGoal/types'
import {
  IConfig,
  IDays,
  IGoal,
  IInitialCommission,
  IMonthGoal,
  IUserWorking
} from 'store/modules/goal/types'

interface SelectorStore {
  state: DefaultRootState
  store: IStoreProps
}

interface SelectorCalendarGoal {
  state: DefaultRootState
  calendarGoal: ICalendarGoalProps
}

interface UseParamsProps {
  storeId: string
  goalId: string
}

export interface IDwt {
  days: number[]
  weeks: number[]
  total: number
}

interface IDataDaysWeightSuggestion {
  days: number[]
  metadata: {
    fromStore: boolean
    soldExpected: number
    amountOfInputsConsidered: number
    month: string
  }
}

export interface ICurrentGoalProps extends IGoal {
  daysWeightSuggestion: number[]
  lastDaysBackup: IDays[]
}

interface IDataSections {
  end: Date | null
  start: Date
}

interface SectionList {
  start: Date
  end: Date | null
  goal: number
  daysWorking: number
}

interface ISection {
  days: number
  goal: number
  start: Date
  end: Date
}

interface CustomIMonthGoal
  extends Omit<IMonthGoal, '_id' | 'initialCommission'> {
  _id?: string
  initialCommission?: IInitialCommission
}

interface GoalStepProps {
  data: ICurrentGoalProps
  dataMonth: string
  dataSections: IDataSections[]
  dataUsersWorking: IUserWorking[]
  dataMainGoals: {
    price: number
    ticket: number
    total: number
  }
  dataDsr: number
  dataDaysWeightSuggestion: number[]
  dataMonthGoals: IMonthGoal[]
  dataDays: IDays[]
  dataLastDaysBackup: IDays[]
  dataConfig: IConfig
  defaultDays: IDays[]
  handleNextStep: () => void
  updateGoal: (obj: any) => void
  updateCurrentGoal: (obj: any) => void
  setDeleteStep: (steps: string[]) => void
  setResetModal: (steps: string[]) => void
  setDeleteGoalStep: (steps: string[]) => void
  deleteGoalStep: (steps: string[]) => void
  goalList: IGoal[]
  loadingUpload: boolean
}

const GoalStep = ({
  data,
  dataMonth,
  dataSections,
  dataUsersWorking,
  dataMainGoals,
  dataDsr,
  dataDaysWeightSuggestion,
  dataMonthGoals,
  dataDays,
  dataLastDaysBackup,
  dataConfig,
  defaultDays,
  handleNextStep,
  updateGoal,
  updateCurrentGoal,
  setDeleteStep,
  setResetModal,
  setDeleteGoalStep,
  deleteGoalStep,
  goalList,
  loadingUpload
}: GoalStepProps) => {
  // MonthGoals
  const [monthGoals, setMonthGoals] = useState<IMonthGoal[]>(data.monthGoals)
  const [isCreatingSection, setIsCreatingSection] = useState(false)
  const [hasFixedCommission, setHasFixedCommission] = useState(false)
  const [fixedCommission, setFixedCommission] = useState<
    number | null | string
  >(null)
  // UsersWorking
  const [users, setUsers] = useState<IUserWorking[]>([])
  const [usersWorking, setUsersWorking] = useState<IUserWorking[]>([])
  const [usersAccWeight, setUsersAccWeight] = useState(0)
  const [isUsersWeightLocked, setIsUsersWeightLocked] = useState(false) // state para cuidar do botao de reset
  const [monthGoalLoading, setMonthGoalLoading] = useState(false)

  // Days
  const [days, setDays] = useState(defaultDays) // Days que vai ser renderizado no calendario (apenas de renderizacao)
  const [sections, setSections] = useState<IDataSections[]>([]) // Sections pura pra ir pro back
  const [sectionsList, setSectionsList] = useState<SectionList[]>([]) // Sections com valores dos períodos e qtd de dias

  // Auxs
  const [suggestionModal, setSuggestionModal] = useState(false)
  const [suggestionAnimationModal, setSuggestionAnimationModal] =
    useState(false)
  const [totalDaysValue, setTotalDaysValue] = useState(0)
  const [sectionsOpened, setSectionsOpened] = useState(false)
  const [isLoadingSuggestionRequest, setIsLoadingSuggestionRequest] =
    useState(false)
  const [isCalendarLocked, setIsCalendarLocked] = useState(true)

  // DWT => Day, Week, Total
  const [dwt, setDwt] = useState<IDwt>({
    days: new Array(7).fill(0),
    weeks: new Array(getWeeksInMonth(new Date(dataMonth))).fill(0),
    total: 0
  })

  const initialCommissionValue = useRef<null | number>(null)

  const { store } = useSelector<SelectorStore, IStoreProps>(
    state => state.store
  )
  const { days: calendarGoal } = useSelector<
    SelectorCalendarGoal,
    ICalendarGoalProps
  >(state => state.calendarGoal)

  const { storeId, goalId } = useParams<UseParamsProps>()
  const dispatch = useDispatch()
  const token = localStorage.getItem('@NeoPro:token')

  const animationOptions = {
    // Animacao da varinha de sugestao NeoPro
    loop: true,
    autoplay: true,
    animationData: wandAnimation
  }

  const [basicCommissionView, setBasicCommissionView] = useState(false)

  useEffect(() => {
    const lastGoalMonthActive = goalList.find(
      goal => goal.config.active && goal.month === dataMonth
    )
    const lastGoalActive = goalList.find(goal => goal.config.active)
    if (initialCommissionValue.current) {
      setFixedCommission(initialCommissionValue.current)
    } else if (data?.config?.commission?.fixed?.commission) {
      setHasFixedCommission(true)
      setFixedCommission(
        Number((data?.config?.commission?.fixed?.commission * 100).toFixed(2))
      )
      initialCommissionValue.current = Number(
        (data?.config?.commission?.fixed?.commission * 100).toFixed(2)
      )
    } else if (data?.config?.commission?.fixed?.commission === 0) {
      setHasFixedCommission(false)
    } else if (lastGoalMonthActive) {
      const lastGoalMonthCommission =
        lastGoalMonthActive?.config?.commission?.fixed?.commission * 100
      if (lastGoalMonthCommission !== 0 && lastGoalMonthCommission) {
        setHasFixedCommission(true)
        setFixedCommission(lastGoalMonthCommission)
        initialCommissionValue.current = lastGoalMonthCommission
      }
    } else if (lastGoalActive) {
      const lastGoalActiveCommission =
        lastGoalActive?.config?.commission?.fixed?.commission * 100
      if (lastGoalActiveCommission !== 0 && lastGoalActiveCommission) {
        setHasFixedCommission(true)
        setFixedCommission(lastGoalActiveCommission)
        initialCommissionValue.current = lastGoalActiveCommission
      }
    }
  }, [goalList, data?.config?.commission?.fixed?.commission, dataMonth])

  const handleToggleCommissionBase = () => {
    setHasFixedCommission(prevStatus => !prevStatus)
    setFixedCommission(
      !hasFixedCommission ? initialCommissionValue.current : null
    )
  }

  // USERSWORKING
  const handleAddToGoal = (user: IUserWorking) => {
    setUsers(state => state.filter(u => u.userId?._id !== user.userId?._id))

    const updatedUsersWorking = [
      ...usersWorking,
      { ...user, dsr: true, active: true }
    ]
    const distributedUsersWeight = distributeWeights(updatedUsersWorking)
    setUsersWorking(distributedUsersWeight)
  }

  const handleRemoveFromGoal = (user: IUserWorking) => {
    setUsers(state => [
      ...state,
      { ...user, weightLocked: false, active: false }
    ])

    const updatedUsersWorking = [...usersWorking].filter(
      u => u.userId?._id !== user.userId?._id
    )
    const distributedUsersWeight = distributeWeights(updatedUsersWorking)
    setUsersWorking(distributedUsersWeight)
  }

  const handleUpdateUserDSR = (user: IUserWorking, dsr: boolean) => {
    const updatedUsers = [...usersWorking]
    updatedUsers.filter(userW => {
      if (userW.userId?._id === user.userId?._id) {
        userW.dsr = dsr
      }
    })
    setUsersWorking(updatedUsers)
  }

  const handleUpdateUserWeight = (
    user: IUserWorking,
    type: string,
    weight: number
  ) => {
    const updatedUsers = [...usersWorking]

    updatedUsers.filter(userW => {
      // filtra pelo cara que foi alterado
      if (userW.userId?._id === user.userId?._id) {
        userW.goalWeight = +weight
        userW.weightLocked = type === 'fixed'
      } // muda o peso dele e bloqueia ele para nao ser alterado novamente na redistribuicao
    })

    const distributedUsers = distributeWeights(updatedUsers)
    if (!isUsersWeightLocked) setIsUsersWeightLocked(true) // se nenhum vendedor tiver sido alterado ou se ja clicou no cadeado uma vez, bloqueia para poder clicar no cadeado dnv
    setUsersWorking(distributedUsers)
  }

  const distributeWeights = (users: IUserWorking[]) => {
    const usersResults = users.reduce(
      (acc, user, i) => {
        if (user.active && user.goalWeight > 0) {
          acc.accWeights += 1
          if (user.weightLocked) acc.accWeights -= user.goalWeight
          if (!user.weightLocked) acc.usersUnlocked += 1
        }
        if (i === users.length - 1) {
          acc.accWeights = acc.accWeights / acc.usersUnlocked
        }
        return acc
      },
      { accWeights: 0, usersUnlocked: 0 }
    )

    const usersUpdated: IUserWorking[] = deepClone(users).map(
      (user: IUserWorking) => {
        if (user.active && !user.weightLocked && data?.distribute)
          user.goalWeight =
            usersResults.accWeights < 0 ? 0 : usersResults.accWeights
        return user
      }
    )

    return usersUpdated
  }

  const handleUnlockWeightUsers = () => {
    setUsersWorking(state =>
      state.map(user => {
        user.weightLocked = false
        user.goalWeight = 1
        return user
      })
    )

    setIsUsersWeightLocked(false)
  }

  const generateMonthGoals = () => {
    // Meta principal da loja
    const mainGoalValue = dataMainGoals.total / usersWorking.length

    setMonthGoalLoading(true)
    if (monthGoals.length === 0) {
      const hasMonthGoals = goalList.find(
        ({ monthGoals }) => monthGoals.length > 0
      )

      if (hasMonthGoals) {
        const newMonthGoals = hasMonthGoals.monthGoals.map(
          (monthGoal, index) => {
            const { _id, initialCommission, ...monthGoalRest } = monthGoal
            if (index === 0) {
              return {
                ...monthGoalRest,
                value: mainGoalValue
              }
            }
            // Percentual calculado com relação à meta principal (index=0)
            const percentual =
              hasMonthGoals.monthGoals[index].value /
              hasMonthGoals.monthGoals[0].value

            return {
              ...monthGoalRest,
              value: percentual * mainGoalValue
            }
          }
        )
        if (newMonthGoals && newMonthGoals.length > 0) {
          const createMonthGoals = async () => {
            try {
              const response = await api.axios.post(
                `/goal/${goalId}/monthGoals`,
                newMonthGoals
              )

              setMonthGoals(response.data.monthGoals)
              updateCurrentGoal({ monthGoals: response.data.monthGoals })

              updateGoal({
                usersWorking:
                  dataUsersWorking.length > 0
                    ? [...usersWorking, ...users].map(user => ({
                        ...user,
                        userId: user.userId
                      }))
                    : usersWorking.map(user => ({
                        ...user,
                        userId: user.userId
                      }))
              })
            } catch (err) {
              console.log(err)
            } finally {
              setMonthGoalLoading(false)
            }
          }

          createMonthGoals()
        }
      } else {
        updateGoal({
          usersWorking:
            dataUsersWorking.length > 0
              ? [...usersWorking, ...users].map(user => ({
                  ...user,
                  userId: user.userId
                }))
              : usersWorking.map(user => ({
                  ...user,
                  userId: user.userId
                }))
        })
        setMonthGoalLoading(false)
      }
    } else {
      const updatedMonthGoals = monthGoals.map((monthGoal, index) => {
        const { _id } = monthGoal
        if (index === 0) {
          return {
            _id,
            value: mainGoalValue
          }
        }
        // Percentual calculado com relação à meta principal (index=0)
        const percentual = monthGoals[index].value / monthGoals[0].value

        return {
          _id,
          value: percentual * mainGoalValue
        }
      })

      if (updatedMonthGoals && updatedMonthGoals.length > 0) {
        try {
          const updateMonthGoals = async () => {
            const response = await api.axios.put(
              `/goal/${goalId}/monthGoals`,
              updatedMonthGoals
            )

            setMonthGoals(response.data.monthGoals)
            updateCurrentGoal({ monthGoals: response.data.monthGoals })

            updateGoal({
              usersWorking:
                dataUsersWorking.length > 0
                  ? [...usersWorking, ...users].map(user => ({
                      ...user,
                      userId: user.userId
                    }))
                  : usersWorking.map(user => ({
                      ...user,
                      userId: user.userId
                    }))
            })
          }
          updateMonthGoals()
        } catch (err) {
          console.log(err)
        } finally {
          setMonthGoalLoading(false)
        }
      }
    }
  }

  // CALENDAR
  const handleDeleteSections = (i: number) => () => {
    if (i > 0) {
      const updatedSections = [...sections]
      updatedSections.splice(i + 1, sections.length - i)
      updatedSections[updatedSections.length - 1].end = null

      setSections(updatedSections)
    } else setSections([{ start: new Date(days[0].date), end: null }])
  }

  const getSuggestionWeights = async (): Promise<number[]> => {
    //Verifica se possui algum dia no calendário que está utilizando da sugestão NeoPro
    if (dataDaysWeightSuggestion.length > 0) {
      return dataDaysWeightSuggestion
    }
    try {
      setIsLoadingSuggestionRequest(true)
      const { data } = await api.axios.get<IDataDaysWeightSuggestion>(
        `/goal/${goalId}/suggestion`
      )
      if (data) {
        updateGoal({ daysWeightSuggestion: { ...data.days } })
        setIsLoadingSuggestionRequest(false)
        return data.days
      }
      setIsLoadingSuggestionRequest(false)
      return []
    } catch (error) {
      if (error) {
        setIsLoadingSuggestionRequest(false)
      }
      return []
    }
  }

  useEffect(() => {
    if (
      data.config.suggestion &&
      data.daysWeightSuggestion.length > 0 &&
      days.length > 0 &&
      days.every(day => day.goal === days[0].goal) &&
      data.lastDaysBackup.every(day => day.goal === days[0].goal) &&
      !data.daysWeightSuggestion.every(
        weightSuggestion => weightSuggestion === data.daysWeightSuggestion[0]
      )
    ) {
      const newDays = days.map((day, i) => {
        return {
          ...day,
          goal: data.mainGoals.total * data.daysWeightSuggestion[i]
        }
      })

      dispatch(setInitialCalendarValues(newDays))
      setDays(newDays)
      setDwt(generateDwt(newDays))
      updateGoal({ lastDaysBackup: newDays })
    }
  }, [
    data.daysWeightSuggestion,
    days,
    data.lastDaysBackup,
    data.config.suggestion
  ])

  const generateDwt = (d: IDays[]) =>
    d.reduce(
      (acc, day) => {
        const weekStartIndex = getDay(startOfMonth(new Date(dataMonth)))
        const i = weekStartIndex + (getDate(new Date(day.date)) - 1)
        const weekIndex = Math.floor(i / 7)

        acc.weeks[weekIndex] += day.goal
        acc.days[i - weekIndex * 7] += day.goal
        acc.total += day.goal

        return acc
      },
      {
        days: new Array(7).fill(0) as number[],
        weeks: new Array(getWeeksInMonth(new Date(dataMonth))).fill(
          0
        ) as number[],
        total: 0
      }
    )

  const handleSuggestion = async () => {
    // Callback de preencher dias com a sugestão
    try {
      const suggestionDays = await getSuggestionWeights()
      if (suggestionDays) {
        const suggestionArray =
          suggestionDays ?? data.daysWeightSuggestion ?? []

        if (suggestionArray.length === 0) {
          throw new Error()
        }

        const daysClone = [...days]

        const disabledDaysWorking: number[] = []
        daysClone.forEach((day, index) => {
          // verifica se o dia nao esta sendo trabalhado
          if (!day.working) {
            disabledDaysWorking.push(index)
          }
        })

        const daysWithFixedGoalIndex: number[] = []

        daysClone.forEach((day, index) => {
          if (day.goalLocked) {
            daysWithFixedGoalIndex.push(index)
          }
        })

        const newDate = new Date()
        const currentMonth = newDate.getMonth()
        const currentYear = newDate.getFullYear()

        const month = new Date(data.month)
        const calendarMonth = month.getMonth()
        const calendarYear = month.getFullYear()
        const daysInCalendarMonth = new Date(
          calendarYear,
          calendarMonth + 1,
          0
        ).getDate()

        const today =
          calendarYear > currentYear
            ? 0
            : currentMonth === calendarMonth
            ? newDate.getDate()
            : currentMonth > calendarMonth
            ? daysInCalendarMonth
            : 0

        const weights = daysClone.reduce(
          (acc, day, index) => {
            if (!daysWithFixedGoalIndex.includes(index)) {
              acc.totalWeight += suggestionArray[index]
              if (disabledDaysWorking.includes(index)) {
                acc.notSelectedDaysWeight += suggestionArray[index]
              }
            }
            return acc
          },
          {
            notSelectedDaysWeight: 0,
            totalWeight: 0
          }
        )

        const fixedDaysGoalValue = daysWithFixedGoalIndex.reduce(
          (acc, curr) => {
            return (acc += days[curr].goal)
          },
          0
        )

        const mainGoalValueWithoutFixedDaysGoal =
          data.mainGoals.total - fixedDaysGoalValue

        const calculateNewGoal = (index: number) => {
          return (
            ((suggestionArray[index] +
              (suggestionArray[index] * weights.notSelectedDaysWeight) /
                (weights.totalWeight - weights.notSelectedDaysWeight)) *
              mainGoalValueWithoutFixedDaysGoal) /
            weights.totalWeight
          )
        }

        const newDays: IDays[] = daysClone.map((day, index) => {
          if (
            day.working &&
            !disabledDaysWorking.includes(index) &&
            !daysWithFixedGoalIndex.includes(index)
            // Essa linha faz com que a redistribuição passe apenas pros dias futuros
            //  && index > (isCalendarLocked ? today - 1 : -1)
          ) {
            return {
              ...day,
              goal: calculateNewGoal(index)
            }
          } else if (daysWithFixedGoalIndex.includes(index)) {
            return {
              ...day,
              goal: daysClone[index].goal,
              goalLocked: true
            }
          } else if (index > (isCalendarLocked ? today - 1 : -1)) {
            return {
              ...day,
              goal: 0
            }
          }
          return day
        })

        dispatch(setInitialCalendarValues(newDays))
        setDays(newDays)
        setDwt(generateDwt(newDays))
        updateGoal({
          lastDaysBackup: newDays,
          daysWeightSuggestion: suggestionDays,
          config: { ...dataConfig, suggestion: true }
        })
        setSuggestionAnimationModal(false)
      }
    } catch (error) {
      console.log(error)
      setSuggestionAnimationModal(false)
      if (error)
        toast.error('Não foi possível gerar uma sugestão para esse mês')
    }
  }

  const differenceBetweenMonths =
    new Date(dataMonth).getFullYear() > new Date().getFullYear()
      ? -1
      : new Date().getMonth() - new Date(dataMonth).getMonth()

  const handleOffSuggestion = (newDays?: IDays[]) => {
    const daysWorking = [...(newDays ? newDays : days)].filter(
      day => day.working
    )

    let amount = dataMainGoals.total
    const part = (amount / daysWorking.length).toFixed(2)

    const redistributedDays = [...days].map((day, i) => {
      if (day.working) {
        if (
          i !==
          days.findIndex(day => day === daysWorking[daysWorking.length - 1])
        ) {
          amount -= parseFloat(part)
          return { ...day, goal: parseFloat(part) }
        } else return { ...day, goal: amount }
      } else return { ...day, goal: 0 }
    })

    setDays(redistributedDays)
    setDwt(generateDwt(redistributedDays))
    updateGoal({
      lastDaysBackup: redistributedDays,
      config: {
        ...dataConfig,
        suggestion: false
      }
    })
  }

  const handleOpenSections = () => {
    // Callback de abertura do modal de sections
    setIsCreatingSection(true)
    if (dataSections) setSections(dataSections)
  }

  useEffect(() => {
    if (
      data.config.suggestion &&
      !data.config.firstActivation &&
      days.length > 0
    ) {
      handleSuggestion()
    } else if (data.daysWeightSuggestion.length === 0) {
      const getWeights = async () => {
        const suggestionWeights = await getSuggestionWeights()
        updateGoal({
          daysWeightSuggestion: suggestionWeights
        })
      }
      getWeights()
    }
  }, [data.config.suggestion, days.length])

  // OS USEEFFECTS ESTAO EM ORDEM DE ACONTECIMENTOS NA ETAPA DE META
  useEffect(() => {
    if (!store) dispatch(storeRequest(storeId, token))

    if (dataUsersWorking.length > 0) scroll.scrollToBottom()
    else scroll.scrollToTop()
  }, [])

  // DEFINE A LISTA DE USUARIOS PARA RENDERIZAR OS VENDEDORES
  useEffect(() => {
    if (store && store.users.length > 0) {
      // users => lista da esquerda | usersWorking => lista da direita
      if (dataUsersWorking.length > 0) {
        const usersFromStore = store.users
          .filter(user => {
            const currentStore = user.stores.find(
              userStore => userStore.storeId === store._id
            )
            //Seleciona só quem não estiver no usersWorking e for do tipo seller
            return (
              currentStore?.type === 'seller' &&
              (dataUsersWorking.length > 0
                ? !dataUsersWorking.find(
                    userW => userW.userId?._id === user._id
                  )
                : true)
            )
          })
          .map(user => ({
            _id: user._id,
            userId: { name: user.name, photo: user.photo, _id: user._id },
            dayOff: {
              enable: false
            },
            goalWeight: 1,
            dsr: dataDsr > 0,
            active: false,
            weightLocked: false
          }))

        // pode ter alguem com active false mas no working por causa do componente no overview
        const users = dataUsersWorking.reduce(
          (acc, user) => {
            const usersLinkedToStore = store.users.some(
              ({ _id }) => _id === user.userId?._id
            )

            if (usersLinkedToStore) {
              if (user.active) acc.working = [...acc.working, user]
              else {
                acc.notWorking = [...acc.notWorking, { ...user, active: false }]
              }
            }
            return acc
          },
          { working: [] as IUserWorking[], notWorking: [] as IUserWorking[] }
        )

        if (users.working.length === 0) {
          updateGoal({ usersWorking: [] })
          scroll.scrollToTop()
        }

        setUsers([...users.notWorking, ...usersFromStore])
        setUsersWorking(users.working)
      } else {
        if (usersWorking.length === 0) {
          //Todos os usuários
          const sellers = store.users.filter(user => {
            const currentStore = user.stores.find(
              userStore => userStore.storeId === store._id
            )
            return (
              currentStore?.type === 'seller' &&
              (users.length > 0
                ? !users.find(u => u.userId?._id === user._id)
                : true)
            )
          })
          setUsersAccWeight(sellers.length * 100)
          setUsersWorking(
            //Seleciona só quem ja estiver no usersWorking (sem esse find, ele joga todo mundo pra direita, e precisa manter na esquerda quem tava na esquerda)
            sellers.map(user => {
              return {
                _id: '',
                userId: {
                  _id: user._id,
                  name: user.name,
                  photo: user.photo
                },
                dayOff: {
                  enable: false
                },
                goalWeight: 1,
                dsr: dataDsr > 0 ? true : false,
                active: true,
                weightLocked: false
              }
            }) //Coloca no padrão se nunca tiver preenchido ou se alterou e salvou
          )
        }
      }
    }
  }, [store, dataUsersWorking, goalList.length])

  useEffect(() => {
    if (!(goalList.length > 0)) {
      setMonthGoalLoading(false)
    }
  }, [goalList.length])

  useEffect(() => {
    // Modifica o acumulo de pesos sempre que sair ou entrar um vendedor
    setUsersAccWeight(
      usersWorking.reduce((acc, user) => (acc += user.goalWeight), 0) * 100
    )
  }, [usersWorking])

  // DEFINE OS DIAS QUE SERAO RENDERIZADOS NO CALENDARIO
  useEffect(() => {
    if (dataMonth && dataUsersWorking.length > 0) {
      // Caso esteja criando pega o default devolvido pelo backend
      if (dataDays.length === 0) {
        dispatch(setInitialCalendarValues(defaultDays))
        setDwt(generateDwt(defaultDays))
        updateGoal({ lastDaysBackup: defaultDays })
        setDays(defaultDays)
      } else {
        // Caso esteja editando pega o valor antigo já existente
        dispatch(setInitialCalendarValues(dataDays))
        setDwt(generateDwt(dataDays))
        setDays(dataDays)
        updateGoal({ lastDaysBackup: dataDays })
      }
    }
  }, [dataMonth, dataUsersWorking, defaultDays])

  //  DEFINE VALORES NO DWT E A LISTAGEM DE PERIODOS DENTRO DO MODAL DE SECTIONPICKER
  useEffect(() => {
    // SECTIONS LIST
    if (!(dataSections.length > 0) && sections && days.length > 0) {
      const newSectionList = [...sections]
        .filter(section => section.start && section.end)
        .map(section => ({
          start: new Date(section.start),
          end: section.end ? new Date(section.end) : section.end,
          goal: 0,
          daysWorking: 0
        }))

      days.forEach(day => {
        if (day.working) {
          newSectionList.map((section, i) => {
            if (
              new Date(day.date) >= section.start &&
              section.end &&
              new Date(day.date) <= section.end
            ) {
              section.goal += day.goal
              section.daysWorking++
            }

            return section
          })
        }
      })
      updateGoal({ sectionsList: newSectionList, days })
      setSectionsList(newSectionList)
    }
  }, [days, sections])

  useEffect(() => {
    if (dataSections.length >= 1)
      setSections(
        [...dataSections].map(section => ({
          start: new Date(section.start),
          end: section.end ? new Date(section.end) : section.end
        }))
      )
    else setSections([{ start: new Date(days[0]?.date), end: null }])
  }, [dataSections])

  //Caso o calendário esteja sendo renderizado, seta como true a parte de "Meta dos vendedores", para que seja renderizado tambem.
  useEffect(() => {
    if (
      (dataUsersWorking.length > 0 &&
        dataMonthGoals.length > 0 &&
        dataConfig?.commission?.fixed?.commission >= 0) ||
      dataDays.length > 0
    ) {
      setBasicCommissionView(true)
    }
  }, [isLoadingSuggestionRequest])

  useEffect(() => {
    if (
      ((dataUsersWorking.length > 0 &&
        dataMonthGoals.length > 0 &&
        dataConfig?.commission?.fixed?.commission >= 0) ||
        (dataDays.length > 0 && !!dataMainGoals?.total)) &&
      !(basicCommissionView || monthGoalLoading) &&
      !(isLoadingSuggestionRequest && !suggestionAnimationModal)
    ) {
      const goalDaysSum = data.days.reduce(
        (acc, currDay) => (acc += currDay.goal),
        0
      )
      if (!(Number(goalDaysSum.toFixed(2)) === dataMainGoals.total)) {
        deleteGoalStep(['days'])
      }
    }
  }, [
    dataUsersWorking,
    dataMonthGoals,
    dataConfig,
    dataDays,
    data,
    basicCommissionView,
    monthGoalLoading,
    isLoadingSuggestionRequest,
    suggestionAnimationModal
  ])

  return (
    <>
      <Container>
        <Element name='sellers'>
          <div className='goals-sellers-step'>
            <div
              style={
                dataUsersWorking?.length > 0
                  ? { pointerEvents: 'none', opacity: 0.3 }
                  : { pointerEvents: 'auto', opacity: 1 }
              }
            >
              <h1 className='goals-sellers-step-title'>
                Selecione quem irá participar da meta
              </h1>
              <div className='goals-sellers-content-boxes'>
                <ContentBox>
                  <div className='contentbox-title'>
                    <div>
                      <h1>Não participam</h1>
                      <span>{users.length}</span>
                    </div>
                  </div>
                  <ul>
                    {users.map((user, i) => (
                      <TransferListCard
                        key={i}
                        user={user}
                        onClick={() => handleAddToGoal(user)}
                      />
                    ))}
                  </ul>
                </ContentBox>
                <ContentBox>
                  <div className='contentbox-title'>
                    <div>
                      <h1>Participam da meta</h1>
                      <span>{usersWorking.length}</span>
                    </div>
                    <div>
                      <AccWeightCounter
                        error={
                          usersAccWeight.toFixed(0) !==
                            (
                              usersWorking.filter(user => user.goalWeight > 0)
                                .length * 100
                            ).toFixed(0) && data?.distribute
                        }
                      >
                        <div data-tip data-for='total-weight-tooltip'>
                          <Tooltip
                            fixed
                            place='top'
                            id='total-weight-tooltip'
                            content={
                              <p>
                                {usersAccWeight.toFixed(0) !==
                                  (
                                    usersWorking.filter(
                                      user => user.goalWeight > 0
                                    ).length * 100
                                  ).toFixed(0) && data?.distribute
                                  ? `Ajuste os pesos para totalizar ${(
                                      usersWorking.length * 100
                                    ).toFixed(0)}%`
                                  : 'Total de pesos acumulado dos vendedores'}
                              </p>
                            }
                          />
                          <SpeedIcon fontSize='small' />
                          <h3 className='weight'>
                            {usersAccWeight.toFixed(0)}%
                          </h3>
                        </div>
                        <div data-tip data-for='locker-weight-tooltip'>
                          {isUsersWeightLocked && (
                            <>
                              <Tooltip
                                fixed
                                place='top'
                                id='locker-weight-tooltip'
                                content={
                                  <p>
                                    Desafixar peso dos vendedores para cálculo
                                    de Peso Automático
                                  </p>
                                }
                              />
                              <button
                                className='unlock-sellers-button'
                                onClick={handleUnlockWeightUsers}
                              >
                                <LockArrow />
                              </button>
                            </>
                          )}
                        </div>
                      </AccWeightCounter>
                    </div>
                  </div>
                  <ul>
                    {usersWorking.map((userW, i) => (
                      <TransferListCard
                        usersWorking={usersWorking}
                        working
                        key={i}
                        user={userW}
                        inGoal
                        data={data}
                        updateUserDSR={handleUpdateUserDSR}
                        updateUserWeight={handleUpdateUserWeight}
                        onClick={() => handleRemoveFromGoal(userW)}
                      />
                    ))}
                  </ul>
                </ContentBox>
              </div>
            </div>
            {Array.isArray(dataUsersWorking) &&
              usersWorking &&
              (!(dataUsersWorking.length > 0) && usersWorking.length >= 1 ? (
                <Link to='monthGoals' offset={-170} smooth={true}>
                  <button
                    onClick={() => {
                      usersWorking.length !==
                        Number(usersAccWeight.toFixed(0)) / 100 &&
                        setResetModal(['usersWorking', 'days', 'dwt'])
                      setDeleteGoalStep(['monthGoals', 'fixed'])
                      setBasicCommissionView(true)

                      const mainGoalValue =
                        dataMainGoals.total / usersWorking.length

                      if (
                        !data.config.firstActivation &&
                        data.config.firstActivation !== undefined &&
                        (!(monthGoals.length > 0) ||
                          mainGoalValue !== monthGoals[0].value)
                      ) {
                        generateMonthGoals()
                      } else {
                        updateGoal({
                          usersWorking:
                            dataUsersWorking.length > 0
                              ? [...usersWorking, ...users].map(user => ({
                                  ...user,
                                  userId: user.userId
                                }))
                              : usersWorking.map(user => ({
                                  ...user,
                                  userId: user.userId
                                }))
                        })
                      }
                    }}
                    disabled={
                      usersWorking.filter(user => user.goalWeight > 0)
                        .length !==
                        Number(usersAccWeight.toFixed(0)) / 100 &&
                      data?.distribute
                    }
                    className='stepper-button-next'
                    data-cy='pickSellersForGoalNextButton'
                  >
                    Avançar
                  </button>
                </Link>
              ) : (
                dataUsersWorking.length > 0 && (
                  <button
                    onClick={() => {
                      setBasicCommissionView(false)
                      setDeleteStep(['usersWorking', 'days', 'dwt'])
                    }}
                    className='stepper-button-edit'
                  >
                    Alterar
                    <CreateIcon
                      style={{ marginLeft: 5, fontSize: 18 }}
                      fontSize='small'
                    />
                  </button>
                )
              ))}
          </div>
        </Element>
        <Element name='monthGoals'>
          {basicCommissionView &&
          !monthGoalLoading &&
          (!isLoadingSuggestionRequest || suggestionAnimationModal) &&
          (dataUsersWorking.length > 0 || monthGoals.length > 0) ? (
            <div className='goals-sellers-step'>
              <div
                style={
                  dataConfig?.commission?.fixed?.commission >= 0 &&
                  dataMonthGoals?.length > 0
                    ? { pointerEvents: 'none', opacity: 0.3 }
                    : { pointerEvents: 'auto', opacity: 1 }
                }
              >
                <h1 className='goals-sellers-step-title'>
                  Meta dos vendedores
                </h1>
                <div className='goals-sellers-goal-table'>
                  <ToggleItem
                    checked={hasFixedCommission}
                    commissionValue={fixedCommission}
                    toggleCommissionBase={handleToggleCommissionBase}
                    setFixedCommission={setFixedCommission}
                    dataCy='commissionBaseGoalSwitch'
                  />
                  <GoalsTable
                    goal={data}
                    monthGoals={monthGoals}
                    setMonthGoals={setMonthGoals}
                    updateCurrentGoal={updateCurrentGoal}
                    usersWorking={usersWorking}
                  />
                </div>
              </div>
              {dataConfig.commission?.fixed?.commission >= 0 &&
              monthGoals.length > 0 ? (
                <Link to='monthGoals' offset={-170} smooth={true}>
                  <button
                    onClick={() => {
                      setDeleteStep(['monthGoals', 'fixed'])
                    }}
                    className='stepper-button-edit'
                  >
                    Alterar
                    <CreateIcon
                      style={{ marginLeft: 5, fontSize: 18 }}
                      fontSize='small'
                    />
                  </button>
                </Link>
              ) : (
                monthGoals.length > 0 && (
                  <Link to='calendar' offset={-100} smooth={true}>
                    <button
                      data-cy='sellersGoalNextButton'
                      onClick={() => {
                        const monthGoalsBackendFormat = monthGoals.map(
                          monthGoal => {
                            if (
                              monthGoal.bonusPercentage === 'no-bonus' &&
                              monthGoal.bonus === 0
                            ) {
                              return {
                                ...monthGoal,
                                bonusPercentage: false,
                                bonus: 0
                              }
                            }
                            return monthGoal
                          }
                        )
                        const commissionWithTwoDecimals = Number(
                          Number(fixedCommission).toFixed(2)
                        )
                        initialCommissionValue.current =
                          commissionWithTwoDecimals
                        updateCurrentGoal({
                          monthGoals: monthGoalsBackendFormat,
                          config: {
                            ...dataConfig,
                            commission: {
                              ...dataConfig.commission,
                              fixed: {
                                commission: commissionWithTwoDecimals
                                  ? commissionWithTwoDecimals / 100
                                  : 0,
                                type: 'user'
                              }
                            }
                          }
                        })
                      }}
                      className='stepper-button-next'
                    >
                      Avançar
                    </button>
                  </Link>
                )
              )}
            </div>
          ) : (
            (basicCommissionView || monthGoalLoading) && (
              <div>
                <CircularProgress size={20} />
                <span style={{ fontSize: 22, marginLeft: 10 }}>
                  Calculando dados da Meta...
                </span>
              </div>
            )
          )}
        </Element>

        <Element name='calendar'>
          {((dataUsersWorking.length > 0 &&
            dataMonthGoals.length > 0 &&
            dataConfig?.commission?.fixed?.commission >= 0) ||
            dataDays.length > 0) && (
            <div
              style={
                dataDays?.length > 0 && dataSections.length > 0
                  ? { pointerEvents: 'none', opacity: 0.3 }
                  : { pointerEvents: 'auto', opacity: 1 }
              }
            >
              {(isLoadingSuggestionRequest && !suggestionAnimationModal) ||
              loadingUpload ? (
                !basicCommissionView && (
                  <div>
                    <CircularProgress size={20} />
                    <span style={{ fontSize: 22, marginLeft: 10 }}>
                      Calculando dados do Calendário...
                    </span>
                  </div>
                )
              ) : (
                <CalendarContainer suggestionActive={dataConfig.suggestion}>
                  <div className='title'>
                    <h1>
                      Meta da loja para{' '}
                      {dataMonth
                        ? format(new Date(dataMonth), 'MMMM', {
                            locale: ptBR
                          })
                        : '...'}
                    </h1>
                    <div className='proft-suggestion-container'>
                      {dataConfig.suggestion &&
                        differenceBetweenMonths > -1 && (
                          <button
                            onClick={() => {
                              if (!isCalendarLocked) {
                                // verifica se o calendarGoal esta populado, caso nao esteja ele chama a funcao
                                // baseado no dataConfig.suggestion e popula o calendarGoal
                                if (!(calendarGoal.length > 0)) {
                                  dataConfig.suggestion
                                    ? handleSuggestion()
                                    : handleOffSuggestion()
                                } else {
                                  setDwt(generateDwt(calendarGoal))
                                  setDays([...calendarGoal])
                                  dispatch(clearDisabledDays())
                                }
                              }
                              setIsCalendarLocked(prev => !prev)
                            }}
                            style={{
                              background: isCalendarLocked
                                ? '#2b2b2a'
                                : '#9E9E9E'
                            }}
                          >
                            {isCalendarLocked ? <LockIcon /> : <LockOpenIcon />}
                          </button>
                        )}

                      <button // o estilo do botao muda pela prop no CalendarContainer
                        data-cy='toggleNeoProSuggestionButton'
                        onClick={() => {
                          if (dataConfig.suggestion) handleOffSuggestion()
                          else setSuggestionModal(!suggestionModal)
                        }}
                      >
                        <WandIcon color={'#fff'} size={10} />
                        <span style={{ marginLeft: 7 }}>{`Sugestão NeoPro ${
                          dataConfig.suggestion ? 'Ativada' : 'Desativada'
                        }`}</span>
                      </button>

                      <ErrorOutlineOutlinedIcon
                        fontSize='small'
                        style={{ color: '#9E9E9E', marginLeft: 7 }}
                        data-tip
                        data-for='tooltip-proft-suggestion'
                      />
                      <Tooltip
                        content={
                          <p>
                            As metas dos dias serão ajustadas para a sua loja
                            pela nossa Inteligência Artificial.
                          </p>
                        }
                        id='tooltip-proft-suggestion'
                      />
                      <Modal open={suggestionModal}>
                        <PromptModal
                          size='md'
                          icon={
                            <WandIcon color={palette.primary.main} size={20} />
                          }
                          onClose={() => setSuggestionModal(false)}
                          title='Aplicar a Sugestão NeoPro?'
                          description='
                    Nossa Inteligência Artificial analisa sua loja e mercado em vários aspectos.
                    A partir desses dados, é calculada uma Sugestão NeoPro de meta do mês.'
                          onLeft={() => setSuggestionModal(false)}
                          onRight={() => {
                            setSuggestionModal(false)
                            setSuggestionAnimationModal(true)

                            handleSuggestion()
                          }}
                          rightTitle='Fazer a mágica'
                        >
                          <span
                            style={{
                              color: '#546E7A',
                              fontSize: 14,
                              textAlign: 'center',
                              fontWeight: 600
                            }}
                          >
                            Atenção: os valores já inseridos nos dias serão
                            perdidos!
                          </span>
                        </PromptModal>
                      </Modal>
                      <Modal open={suggestionAnimationModal}>
                        <SuggestionAnimation>
                          <Lottie
                            options={animationOptions}
                            width={200}
                            height={200}
                          />
                          <span>
                            <strong>Fazendo a mágica...</strong> <br />
                            Calculando sugestão
                          </span>
                        </SuggestionAnimation>
                      </Modal>
                    </div>
                  </div>
                  <Calendar
                    data={data}
                    dwt={dwt}
                    setDwt={setDwt}
                    days={days}
                    setDays={setDays}
                    weekStartIndex={getDay(new Date(dataMonth))}
                    month={new Date(dataMonth)}
                    mainGoalValue={dataMainGoals?.total}
                    setTotalDaysValue={setTotalDaysValue}
                    updateGoal={updateGoal}
                    suggestion={dataConfig.suggestion}
                    isCalendarLocked={isCalendarLocked}
                    usersWorking={usersWorking}
                  />
                  <div className='footer'>
                    {!(dataDays?.length > 0) && (
                      <>
                        {dataSections.length > 1 && (
                          <button
                            onClick={handleOpenSections}
                            className={`create-period-button ${
                              !sectionsOpened
                                ? 'create-period-button-need-review'
                                : dataSections.length > 0 &&
                                  'create-period-button-completed'
                            }`}
                          >
                            <span>
                              {dataSections?.length > 0
                                ? dataSections?.length
                                : 0}
                            </span>
                            {dataSections?.length === 1
                              ? 'Período'
                              : 'Períodos'}
                          </button>
                        )}

                        <div data-tip data-for={'tooltip-distribute-issue'}>
                          <button
                            data-cy='calendarNextButton'
                            disabled={
                              Number(totalDaysValue).toFixed(2) !==
                                Number(dataMainGoals?.total).toFixed(2) ||
                              (dataSections.length >= 2 && !sectionsOpened)
                            }
                            onClick={() => {
                              updateCurrentGoal({
                                lastDaysBackup: days,
                                days: days,
                                sections: dataSections ? dataSections : sections
                              })
                              scroll.scrollToBottom()
                            }}
                            style={{ margin: 0 }}
                            className='stepper-button-next'
                          >
                            Avançar
                          </button>
                        </div>

                        {((dataSections.length >= 2 && !sectionsOpened) ||
                          Number(totalDaysValue).toFixed(2) !==
                            Number(dataMainGoals?.total).toFixed(2)) && (
                          <Tooltip
                            id='tooltip-distribute-issue'
                            content={
                              <p>
                                {Number(totalDaysValue).toFixed(2) !==
                                Number(dataMainGoals?.total).toFixed(2)
                                  ? 'É necessário que a soma das metas diárias resultem exatamente no total definido para a meta da loja!'
                                  : !sectionsOpened &&
                                    'Você precisa revisar a criação de períodos para prosseguir!'}
                              </p>
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </CalendarContainer>
              )}
            </div>
          )}

          {dataDays?.length > 0 &&
            dataSections?.length > 0 &&
            !isLoadingSuggestionRequest && (
              <Link to='calendar' offset={-170} smooth={true}>
                <button
                  onClick={() => {
                    setDeleteStep(['days'])
                  }}
                  className='stepper-button-edit'
                >
                  Alterar
                  <CreateIcon
                    style={{ marginLeft: 5, fontSize: 18 }}
                    fontSize='small'
                  />
                </button>
              </Link>
            )}
        </Element>
        <Element name='next-button-step'>
          {dataDays?.length > 0 &&
            dataSections.length > 0 &&
            !isLoadingSuggestionRequest && (
              <button
                onClick={() => {
                  const hasSomeWorkingDay = dataDays.some(day => day.working)
                  if (hasSomeWorkingDay) {
                    handleNextStep()
                  } else {
                    toast.warn('A meta deve ter pelo menos um dia de trabalho')
                  }
                }}
                style={{ margin: 'auto' }}
                className='stepper-button-next'
                data-cy='nextStepButton'
              >
                Próxima etapa
                <ArrowForwardIos className='next-button-icon' />
              </button>
            )}
        </Element>
      </Container>

      {/*   MODAL DE SECTIONS COM SECTIONPICKER   */}
      <Modal open={isCreatingSection}>
        <ModalContainer>
          <div className='top'>
            <div className='title'>
              <h1>Períodos da meta</h1>
              <span>Adicione e distribua os períodos da meta.</span>
            </div>
            <Close
              className='close-button'
              onClick={() => setIsCreatingSection(false)}
            />
          </div>
          <div className='center'>
            <div className='calendar'>
              <SectionPicker
                days={days}
                sections={sections}
                setSections={setSections}
              />
            </div>
            <div className='sections-table'>
              <div className='title'>
                <span>#</span>
                <span>Meta do Período</span>
                <span>Dias úteis</span>
              </div>
              {sections[0]?.end ? (
                <div className='table'>
                  {days
                    .reduce(
                      (sections: ISection[], day) => {
                        // Passa por todos os days já somando a meta dentro de cada section
                        if (day.working) {
                          sections = sections.map(section => {
                            if (
                              +new Date(day.date) >= +new Date(section.start) &&
                              section.end &&
                              +new Date(day.date) <= +new Date(section.end)
                            ) {
                              section.goal += day.goal
                              section.days++
                            }
                            return section
                          })
                        }

                        return sections
                      },
                      sections.reduce((acc: ISection[], { start, end }) => {
                        //Inicializa valor inicial do reduce apenas com sections completas e valores zerados
                        if (end)
                          acc.push({
                            start:
                              typeof start === 'string'
                                ? new Date(start)
                                : start,
                            end: typeof end === 'string' ? new Date(end) : end,
                            goal: 0,
                            days: 1
                          })
                        return acc
                      }, [])
                    )
                    .map((section, i) => (
                      <div
                        key={i}
                        className='section'
                        data-tip
                        data-for={`day-range-section-${i}`}
                      >
                        <span className='index'>{i + 1}</span>
                        <span className='section-goal'>
                          {formatPrice(section.goal)}
                        </span>
                        <div className='util-day'>
                          <span>{section.days - 1}</span>
                          <Tooltip
                            id={`day-range-section-${i}`}
                            content={
                              <p>
                                {`Período total: ${format(
                                  new Date(section.start),
                                  'dd/MM'
                                )} - ${format(new Date(section.end), 'dd/MM')}`}
                              </p>
                            }
                          />
                        </div>
                        <button
                          className='delete-button'
                          onClick={handleDeleteSections(i)}
                        >
                          <Delete className='delete-icon' />
                        </button>
                      </div>
                    ))}
                </div>
              ) : (
                <span className='no-table'>
                  Insira os períodos no calendário ao lado!
                </span>
              )}
            </div>
          </div>
          <div className='footer'>
            {sections.length > 0 && (
              <>
                <div className='sections-picker-info'>
                  <Info />
                  <span>
                    Para editar os períodos no calendário, delete (clicando na
                    lixeira de cada período) os períodos já existentes na
                    tabela!
                  </span>
                </div>
                <div data-tip data-for='section-submit-tooltip'>
                  <button
                    onClick={() => {
                      setIsCreatingSection(false)
                      setSectionsOpened(true)
                      updateGoal({ sections, sectionsList })
                    }}
                    disabled={
                      sections.length < 2 ||
                      !sections[sections.length - 1].end ||
                      getDate(
                        new Date(sections[sections.length - 1].end || 31)
                      ) < getDate(endOfMonth(new Date(dataMonth)))
                    }
                    className='section-submit'
                  >
                    Salvar
                  </button>
                </div>
              </>
            )}
          </div>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default GoalStep
